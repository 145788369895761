import {
  FetchFileAttachmentCacheKey,
  FetchFileAttachmentGqlQuery,
  FileAttachmentNanoID,
  FileAttachmentUUID
} from '../../fileAttachmentsTypes';

import { useShowQuery } from '../../../common/hooks/base/reactQuery/useShowQuery';

interface FileAttachmentFields {
  uuid: FileAttachmentUUID;
}

interface FileAttachmentResponse<ItemType extends FileAttachmentFields> {
  fileAttachment: ItemType | null;
}

interface FileAttachmentOptions {
  uuid: FileAttachmentNanoID | FileAttachmentUUID;
  cacheKey: FetchFileAttachmentCacheKey;
  query: FetchFileAttachmentGqlQuery;
  options?: {
    cacheTime?: number;
  };
}

const itemKey = 'fileAttachment';

function useFileAttachment<ItemType extends FileAttachmentFields>({
  uuid,
  cacheKey,
  query,
  options = {}
}: FileAttachmentOptions) {
  const {
    item,
    itemError,
    itemErrorData,
    itemFetched,
    itemLoading,
    itemIsPlaceholderData
  } = useShowQuery<FileAttachmentResponse<ItemType>, ItemType>({
    cacheKey,
    query,
    uuid,
    options,
    itemKey
  });

  return {
    fileAttachment: item,
    fileAttachmentErrorData: itemErrorData,
    fileAttachmentError: itemError,
    fileAttachmentFetched: itemFetched,
    fileAttachmentLoading: itemLoading,
    fileAttachmentIsPlaceholderData: itemIsPlaceholderData
  };
}

export default useFileAttachment;
