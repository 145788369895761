import { useCallback, useState } from 'react';

import { LocalStorageKey } from './useLocalStorageProxyState.types';

function useLocalStorageProxyState<ValueType extends Record<string, any>>(
  key: LocalStorageKey,
  initialValue: ValueType
) {
  const [value, setStoredValue] = useState<ValueType>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);

      return initialValue;
    }
  });

  const localStorageProxyHandler: ProxyHandler<ValueType> = {
    get: useCallback(
      (target: ValueType, targetKey: string) => {
        const storedValue = localStorage.getItem(key);

        return storedValue !== null
          ? JSON.parse(storedValue)?.[targetKey]
          : value?.[targetKey];
      },
      [key, value]
    )
  };

  const localStorageProxy = new Proxy(
    {} as ValueType,
    localStorageProxyHandler
  );

  const setValue = (
    value: ValueType,
    replacer?: (this: any, key: string, value: any) => any
  ) => {
    console.log(value, key, JSON.stringify(value, replacer));
    try {
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(value, replacer));
      }

      setStoredValue(value);
    } catch (error) {
      console.log(error);
    }
  };

  return [localStorageProxy, setValue] as const;
}

export default useLocalStorageProxyState;
